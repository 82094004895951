import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });


//pc



//sp
let menuFlg = true;
let contactFlg = true;
$(document).on('click', '.menu-btn', function () {
  let $this = $(this);
  let $tar = $this.next();
  $this.find('.menu-trigger').stop().toggleClass('active');
  if (menuFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.3
    });
    //$this.find('.word').text('CLOSE');
    //$this.find('.word').addClass('active');
    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    menuFlg = false;
    if (contactFlg == false) {
      gsap.to('.navArea', {
        autoAlpha: 0,
        duration: 0.3
      });
      contactFlg = true;

    }

  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    //$(this).find('.word').text('MENU');
    //$(this).find('.word').removeClass('active');
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    menuFlg = true;
  }
  return false;
});
$(document).on('click', '.navArea > ul > li > a', function () {
  let $this = $(this);
  let $tar = $this.next();
  if (contactFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.3
    });
    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    contactFlg = false;

    if (menuFlg == false) {
      gsap.to('.navArea', {
        autoAlpha: 0,
        duration: 0.3
      });
      //$('.menu-btn').find('.word').text('MENU');
      //$('.menu-trigger').stop().toggleClass('active');
      menuFlg = true;
    }

  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    contactFlg = true;

  }
  return false;
});

$(".navArea ul li > a").on('click', function () {
  let $this = $(this);
  let $tar = $(this).next('.child-menu');
  $this.toggleClass('is_active');
  $tar.stop().slideToggle();
  return false;
});


$(function(){
  var bnr = $('.top_bnr');
  var hide = $('.hide');
  var pos = 0;
  

  $(window).on('scroll', function(){
    
  var elemTop = 100;
  var scroll = $(window).scrollTop();
    
    if(0 > scroll - pos){
      bnr.fadeIn();
      bnr.removeClass(hide);
    }else if(elemTop > scroll){
      bnr.addClass(hide);
      bnr.fadeOut();
    }
    else{
      bnr.addClass(hide);
      bnr.fadeOut();
    }
    pos = scroll;

  });

  (function(){
    var newEvent = new $.Event("scrollstop"),
    timer;
    function newEventTrigger(){
    if (timer) clearTimeout(timer);
    timer = setTimeout(function(){
    $(window).trigger(newEvent)
    }, 1000);
    }
    $(window).on("scroll", newEventTrigger);
    })();

    $(window).on("scrollstop", function(){
      bnr.fadeOut(1000);
      });

});

/*$(function(){

  var bnr = $('.top_bnr');
  var hide = $('.hide');

  $(window).on('scroll', function(){

    
    if($(this).scrollTop() > 100){
      bnr.fadeIn("slow", function () {
        $(this).delay(3000).fadeOut("slow");
      });
    }else{
      bnr.fadeOut();
    }

  });

});*/